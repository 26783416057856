<template>
  <div class="warp">
    <img class="warp-img" :src="detail.bigImg">
    <!-- 头部 -->
    <div class="content">
      <!-- 标题 -->
      <div class="title-box">
        <div class="content-title twoEllipsis">{{ detail.tiitle }}</div>
        <div class="data-num">
          <div v-if="detail.curForms && detail.curForms.length > 0" class="detail-type">
            <span v-for="(curForms, curindex) in detail.curForms" :key="curindex">
              {{ curindex != 0 ? "·" : "" }}{{ curForms }}
            </span>
          </div>
          <div class="icon-div">
            <img class="img" src="@/assets/img/Home/Frame.png" object-fit="contain">
            人气值{{ detail.clicks }}
          </div>
          <div class="icon-div">
            <img class="img-v" src="@/assets/img/Home/validity.png" object-fit="contain">
            有效期{{
              detail.validStartTime
              ? `${detail.validStartTime} - ${detail.validEndTime}`
              : `${detail.validity ? detail.validity : `0`}年`
            }}
          </div>
          <div class="icon-div">
            <img v-if="issc" class="img-vs" src="@/assets/img/Home/z8.png" object-fit="contain" @click="collectProduct()">
            <img v-if="!issc" class="img-vs" src="@/assets/img/Home/z7.png" object-fit="contain"
              @click="collectProduct()">
            收藏
          </div>
        </div>
      </div>
      <!-- 报名资讯按钮 -->
      <div class="class-num">
        <div class="class-btn">
          <div class="price">
            <span class="price-icon"> ￥</span>
            {{ detail.price }}
            <!-- <span class="oldPrice"> ￥{{ detail.olderPrice ? detail.olderPrice : 0 }}</span> -->
          </div>
          <div class="brace" />
          <div v-if="!userInfo || !IsPurchase" class="btnL" @click="zhifu">
            立即购买
          </div>
          <div v-else class="btnL">已购买</div>
        </div>
        <div v-if="domain.isShop == 1" class="content-tips">
          注：知识套餐可以直接购买或单科课程组合购买
        </div>
      </div>
      <div class="box-content" />
    </div>
    <div class="nav-content">
      <!-- <div class=""></div> -->
      <!-- 选项栏 -->
      <div class="class-list box-content">
        <div class="tabs-title">
          <template v-for="(item, index) in tabList">
            <div v-if="item.show" :key="index" class="item">
              <div class="title-text" :class="navIndex == item.type ? 'active' : ''" @click="navs(item.type)">
                {{ item.text }}
              </div>
              <div v-if="navIndex == item.type" class="on" />
            </div>
          </template>
        </div>
        <!-- 知识套餐概述内容 -->
        <div v-if="navIndex == 1" class="warpCon">
          <div v-if="detail.content" class="appleLefts" v-html="ImgSizeLimit(detail.content)" />
          <div v-if="!detail || !detail.content" class="nothing">
            <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
            <div class="nothing-text">暂无内容哦~</div>
          </div>
        </div>
        <!-- 课表 -->
        <div v-if="navIndex == 2" class="warpCon">
          <couser-view :is-purchase="IsPurchase" :product-line="detail.productLine" :is-freeze="detail.isFreeze"
            :classes-id="id" :card-code="cardCode" :kind="detail.kind" />
        </div>
        <!-- 课表 -->
        <div v-if="navIndex == 3" class="warpCon">
          <couser-view-past v-if="navIndex == 3 && smallList.length > 0" :is-purchase="IsPurchase"
            :product-line="detail.productLine" :is-freeze="detail.isFreeze" :classes-id="id" :card-code="cardCode"
            :nav-index="navIndex" :kind="detail.kind" />
        </div>
        <!-- 模考服务 -->
        <div v-if="navIndex == 4" class="warpCon">
          <room-view :is-purchase="IsPurchase" :product-line="detail.productLine" :classes-id="id" />
        </div>
        <!-- 专属资料 -->
        <div v-if="navIndex == 5" class="warpCon">
          <data-view :is-purchase="IsPurchase" :product-line="detail.productLine" :classes-id="id" />
        </div>
        <!-- 专属题库 -->
        <div v-if="navIndex == 6" class="warpCon">
          <exam-view :is-purchase="IsPurchase" :product-line="detail.productLine" :classes-id="id"
            :card-code="cardCode" />
        </div>
        <!-- 专属教cai -->
        <div v-if="navIndex == 8" class="warpCon">
          <!-- <textbook-view></textbook-view> -->

          <!-- <div class="appleLefts" v-if="detail.jcServer" v-html="ImgSizeLimit(detail.jcServer)"></div>
          <div class="nothing" v-if="!detail || !detail.jcServer">
            <img class="nothing-img" src="@/assets/img/study/noLearn.png" />
            <div class="nothing-text">暂无内容哦~</div>
          </div> -->
        </div>
        <!-- 专属教服 -->
        <div v-if="navIndex == 9" class="warpCon">
          <educate :detail="detail" />
          <!-- <div class="appleLefts" v-if="detail.jfServer" v-html="ImgSizeLimit(detail.jfServer)"></div>
          <div class="nothing" v-if="!detail || !detail.jfServer">
            <img class="nothing-img" src="@/assets/img/study/noLearn.png" />
            <div class="nothing-text">暂无内容哦~</div>
          </div> -->
        </div>
        <!-- 课程评价 -->
        <div v-if="navIndex == 7" class="warpCon">
          <score-evaluation v-if="navIndex == 7" :type="1" :type-id="id" :class-id="id" :is-purchase="IsPurchase"
            :type-nmae="detail.tiitle" :product-line="detail.productLine" />
        </div>
      </div>
      <you-love :id="id" class="youlove" :ifteacher="false" :type="1">推荐套餐</you-love>
    </div>

    <div v-if="isFreeze == 1" class="mask_layer">课程已冻结，请联系老师</div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getClass } from '@/api/home'
import chater from '@/components/Know/course/chater.vue'
import couserView from '@/components/Know/classCom/couserView.vue'
import couserViewPast from '@/components/Know/classCom/couserViewPast.vue'
import dataView from '@/components/Know/classCom/dataViewVip.vue'
import examView from '@/components/Know/classCom/examViewVip.vue'
import roomView from '@/components/Know/classCom/roomView.vue'
import educate from '@/components/Know/classCom/educate.vue'
import textbookView from '@/components/Know/classCom/textbookView.vue'
import scoreEvaluation from '@/components/Know/course/scoreEvaluation.vue'
import YouLove from '@/components/Know/youLove.vue'
import { getToken, getDomain } from '@/api/cookies'
import { OrderClient } from '@/api/orderClient'
const orderClient = new OrderClient()
import { Know } from '@/api/know'
const know = new Know()
import { checkStudyCode } from '@/api/home.js'
export default {
  components: {
    roomView,
    YouLove,
    couserView,
    dataView,
    examView,
    scoreEvaluation,
    couserViewPast,
    textbookView,
    educate
  },
  data() {
    return {
      domain: getDomain(),
      tabList: [
        { text: '套餐详情', type: 1, show: true },
        { text: '套餐课表', type: 2, show: true }
      ],
      small: false,
      detail: {},
      navIndex: 1,
      smallList: [],
      id: '',
      type: '',
      userInfo: null,
      user: '',
      tokens: '',
      IsPurchase: false,
      cardCode: null, // 学习卡id
      isFreeze: 0,
      issc: false
    }
  },
  async created() {
    this.cardCode = this.$route.query.cardCode
    this.checkStudyCode()
    this.tokens = getToken()
    this.userInfo = localStorage.getItem('userId')
    this.id = this.$route.query.id
    this.type = this.$route.query.type
    await this.getDetail()
    know.updateCourseAllClicks(1, this.id)
    await this.selectDocList()
    await this.getPastPrivateClassList()
    await this.classesContactList()
    this.getCollectProductStatus()
  },
  methods: {
    collectProduct() {
      if (!this.userInfo) {
        this.$notify({
          title: '错误',
          message: '未登录, 请先去登录',
          type: 'error',
          duration: 2000
        })
        return
      }
      know
        .collectProduct({
          type: 1,
          typeId: this.id,
          stuId: this.userInfo,
          status: this.issc ? 0 : 1
        })
        .then((res) => {
          if (res.code == 0) {
            this.getCollectProductStatus()
          }
        })
    },
    getCollectProductStatus() {
      know
        .getCollectProductStatus({
          type: 1,
          typeId: this.id,
          stuId: this.userInfo
        })
        .then((res) => {
          if (res.code == 0) {
            this.issc = res.data
          }
        })
    },
    /* 验证学习卡 */
    checkStudyCode() {
      if (this.cardCode) {
        const data = {
          cardCode: this.cardCode
        }
        checkStudyCode(data).then((res) => {
          if (res.code == 0) {
            this.IsPurchase = true
            this.$store.commit('IS_PURCHASE_CHANGE', true)
          }
        })
      }
    },
    selectDocList() {
      know.classesCourseDocList(this.id).then((res) => {
        if (res.data.isInclude == 1) {
          this.tabList[2].show = true
        }
      })
    },
    classesContactList() {
      know.classesContactList(this.id).then((res) => {
        if (res.data.isInclude == 1) {
          if (this?.tabList[3]) {
            this.tabList[3].show = true
          }
        }
      })
    },
    // 课程推荐详情
    async getDetail() {
      await getClass(this.id).then((res) => {
        this.isFreeze = res.data.isFreeze
        if (res.data.BX.curForms) {
          const item = res.data.BX
          res.data.BX.curForms = res.data.BX.curForms.split(',')
          if (item.isReference == 0) {
            this.tabList.push({ text: '专属教服', type: 9, show: true })
          }
          // 排除普通班型，普通班型不展示
          if (item.productLine != '1') {
            this.tabList.push(
              { text: '套餐资料', type: 5, show: false },
              { text: '套餐题库', type: 6, show: false }
            )
          }
          this.classesDocKcTableButton()
          this.classesContactTableButton()
          this.tabList.push({ text: '套餐评价', type: 7, show: true })
          // this.tabList.push({ text: '往期课表', type: 3, show: false })
        }

        // if (res.code == 200) {
        res.data.BX.features = res.data.BX.features
          ? res.data.BX.features.split(',')
          : []
        if (!this.cardCode) {
          this.IsPurchase = res.data.IsPurchase
          res.data.BX.isFreeze = res.data.isFreeze
        }
        this.detail = res.data.BX
        this.$forceUpdate()
        // }
      })
    },
    // 知识套餐课程资料年份（优化） 按钮显示
    async classesDocKcTableButton() {
      const { data } = await know.classesDocKcTableButton({
        classesId: this.id,
        coursePackageId: this.direction,
        userid: this.userInfo
      })
      if (data) {
        if (this.tabList[2]) {
          this.tabList[2].show = true
        }
      }
    },
    // 知识套餐课程测试年份（优化） 按钮显示
    async classesContactTableButton() {
      const { data } = await know.classesContactTableButton({
        classesId: this.id,
        coursePackageId: this.direction,
        userid: this.userInfo
      })
      if (data) {
        if (this.tabList[3]) {
          this.tabList[3].show = true
        }
      }
    },
    async getPastPrivateClassList() {
      const res = await know.getPastPrivateClassList(
        this.id,
        this.userInfo ? this.userInfo.id : undefined
      )
      if (res.code == 0) {
        this.smallList = res.data
        if (this.smallList.length > 0) {

          this.tabList.forEach((element) => {
            if (element.text == '往期课表') {
              element.show = false
            }
          })
        }
      }
    },
    // 标签页切换
    navs(val) {
      this.navIndex = val
    },

    // 点击立即报名按钮 跳转支付页
    zhifu() {
      const token = getToken()
      if (token) {
        this.$router.push(`/orders/classOrder?classId=${this.detail.id}`)
        /*     orderClient
          .addOrders(this.detail.id, localStorage.getItem("userId"), this.detail.tiitle, 1)
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: "个人中心订单页",
                params: { orderNumber: res.msg },
              });
            } else {
              this.$message.error(res.msg);
            }
          }); */
      } else {
        Vue.prototype.goLoginView(true)
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    }
  }
}
</script>
<style lang="less" scoped>
.nothing {
  background: #fff;
}

.el-popover {
  overflow: hidden;
  padding: 0px;
}

.warp {
  position: relative;

  .warp-img {
    width: 100%;
    height: 360px;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }

  .content {
    width: 1220px;
    height: 160px;
    background: #ffffff;
    box-shadow: 0px 8px 16px 1px rgba(204, 204, 204, 0.20000000298023224);
    border-radius: 16px 16px 16px 16px;
    opacity: 1;
    position: absolute;
    top: 273px;
    left: calc(50% - 610px);
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 0px 40px 0px;
    justify-content: space-between;

    .box-content {
      position: absolute;
      top: 0px;
      width: 100%;
      left: 0px;
      right: 0px;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: linear-gradient(180deg,
          #fff1f0 0%,
          rgba(255, 255, 255, 0) 100%);
    }

    .title-box {
      z-index: 1000;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .content-title {
        font-size: 26px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 40px;
      }

      .data-num {
        display: flex;
        align-items: center;
        margin-top: 16px;

        .detail-type {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #ff7b1c;
          margin-right: 38px;
          line-height: 16px;
        }

        .icon-div {
          align-items: center;
          display: flex;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          // line-height: 16px;
          margin-right: 30px;

          .img {
            width: 13px;
            height: 16px;
            margin-right: 5px;
          }

          .img-v {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }

          .img-vs {
            margin-right: 5px;
          }
        }
      }
    }

    .class-num {
      flex-shrink: 0;
      z-index: 1000;
      margin-left: 50px;

      .class-btn {
        width: 420px;
        height: 68px;
        border-radius: 34px;
        opacity: 1;
        border: 1px solid #ff5e51;
        display: flex;
        background: linear-gradient(140deg, #ff8551 0%, #ff5e51 100%);
        align-items: center;
        text-align: center;
        position: relative;
        overflow: hidden;

        .clicks {
          width: 50%;
          height: 100%;
          line-height: 66px;
          font-size: 20px;
          font-family: Microsoft YaHei-Bold;
          font-weight: bold;
          background: #fff;
          color: #ff5e51;
        }

        .price {
          width: 50%;
          height: 100%;
          background: #fff;
          font-family: Microsoft YaHei-Regular;
          color: #ff5e51;
          font-size: 32px;
          font-weight: bold;
          line-height: 66px;

          .price-icon {
            font-size: 18px;
            font-weight: 400;
            line-height: 18px;
            margin: auto;
          }

          .oldPrice {
            font-size: 16px;
            font-family: Microsoft YaHei-Regular;
            font-weight: 400;
            color: #999999;
            line-height: 16px;
            text-decoration: line-through;
          }
        }

        .brace {
          position: absolute;
          left: calc(50% - 10px);
          background: #fff;
          width: 20px;
          height: 71px;
          top: -5px;
          flex-shrink: 0;
          transform: rotate(18deg);
        }

        .btnL {
          height: 100%;
          line-height: 66px;
          width: 50%;
          font-size: 20px;
          font-family: Microsoft YaHei-Bold;
          font-weight: bold;
          color: #ffffff;
          cursor: pointer;
        }
      }

      .content-tips {
        margin-top: 16px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        text-align: right;
      }
    }
  }
}

// 选项栏
.tabs-title {
  line-height: 66px;
  height: 66px;
  display: flex;
  padding: 0px 32px;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  background: #fff;

  .item {
    position: relative;
    margin-right: 40px;
    width: auto;
    flex-shrink: 0;
    cursor: pointer;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #666666;

    .active {
      font-size: 16px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }

    .on {
      position: absolute;
      bottom: 0px;
      left: calc(50% - 16px);
      width: 32px;
      height: 4px;
      background: #ff5d51;
      border-radius: 4px;
    }
  }
}

// 选项栏
.warpnav {
  width: 100%;
  height: 60px;
  background: #ffffff;
  border: 1px solid #dddddd;

  // 缩宽
  .warpnavs {
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    // 知识套餐概述
    span {
      width: auto;
      height: auto;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }

    // 竖线
    .line {
      width: 1px;
      height: 14px;
      background: #cccccc;
      margin: 0px 40px;
      margin-top: 5px;
    }

    // 转换内容 选中
    .warpnavsCon {
      padding: 0px 5px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      span {
        width: auto;
        height: auto;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff4027;
        margin-top: 20px;
      }

      div {
        width: 30px;
        height: 3px;
        margin-top: 14px;
        background: linear-gradient(90deg, #ff4027 0%, #ff5400 100%);
      }
    }

    // 转换内容 未选中
    .warpnavsCons {
      padding: 0px 5px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      span {
        width: auto;
        height: auto;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-top: 20px;
      }
    }
  }
}

// 直播课内容
.warpCon {
  width: 100%;
  // height: 920px;
  height: auto;
  margin-bottom: 30px;
  background: #fff;
  overflow: hidden;

  // 缩宽
  .warpCons {
    width: 1200px;
    height: 100%;
    margin: auto;
    overflow: hidden;
    margin-bottom: 50px;

    .warpConss {
      width: 100%;
      height: auto;

      // 单个内容
      .warpConske {
        width: 100%;
        height: 76px;
        margin-top: 30px;
        background: #ffffff;
        border: 1px solid #ffc3bb;
        border-radius: 2px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        // 左侧标题
        .warpConskeL {
          width: auto;
          height: 19px;
          margin-left: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          img {
            width: 21px;
            height: 19px;
            margin-right: 16px;
            cursor: pointer;
          }

          p {
            width: auto;
            height: auto;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #27323f;
            cursor: pointer;

            span {
              width: auto;
              height: auto;
              margin-left: 20px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #7b8196;
            }
          }
        }

        // 右侧展开/收起
        .warpConskeR {
          width: auto;
          height: 15px;
          margin-right: 30px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          cursor: pointer;

          img {
            width: 9px;
            height: 7px;
            margin-left: 7px;
          }

          span {
            width: auto;
            height: auto;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #7b8196;
          }
        }
      }

      // 单个内容展开项盒子
      .warpConskexq {
        width: 100%;
        height: auto;
        background: #ffffff;
        padding: 20px;

        // 某个展开项
        p {
          width: auto;
          height: 13px;
          font-size: 15px;
          margin: 20px 0px 30px 10px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #27323f;
        }

        .warpConskexqs {
          width: 1175px;
          height: 50px;
          margin: 10px auto;
          background: #f7f7f7;
          display: flex;
          justify-content: space-between;
          align-items: center;

          // 左侧
          .warpConskexqsL {
            width: auto;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            div {
              width: 4px;
              height: 50px;
              background: #dddddd;
              margin-right: 27px;
            }

            span {
              width: auto;
              height: auto;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              cursor: pointer;
            }
          }

          // 右侧
          .warpConskexqsR {
            width: 320px;
            height: 32px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            div {
              width: 90px;
              height: 32px;
              margin-right: 10px;
              border-radius: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .apple {
    width: 1200px;
    height: 100%;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .appleLeft {
      width: 750px;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      background: #ffffff;
    }

    .appleRight {
      width: 430px;
      height: 1292px;
      background: #ffffff;

      .appleRightTop {
        width: auto;
        height: 20px;
        margin: 16px 22px;
        border-left: 6px solid #3855f9;
        padding: 0px 12px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #7e7e7e;
      }

      img {
        width: 376px;
        height: 209px;
        margin-top: 25px;
        margin-left: 27px;
        background: rgba(0, 0, 0, 0);
      }
    }
  }

  .appleLefts {
    background: #fff;
    padding: 32px 52px 32px 32px;

    /deep/img {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .kcpingjia {
    width: 65%;
    height: 818px;
    background: #ffffff;
    margin: auto;
    overflow: auto;
    margin-top: 30px;
    margin-bottom: 50px;
    padding: 32px 0px;

    .kOne {
      width: auto;
      height: auto;
      margin: 0px 0px 0px 32px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .kOneL {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 20px;
      }

      .kOneR {
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        // 昵称信息
        .kOneRTop {
          width: auto;
          height: 23px;
          margin-top: 5px;
          margin-bottom: 28px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          span {
            width: auto;
            height: auto;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            margin-right: 12px;
          }

          img {
            width: 14px;
            height: 14px;
            margin-right: 8px;
          }

          div {
            width: 42px;
            height: 23px;
            background: #ff4026;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
        }

        // 聊天内容
        .kOneRCon {
          width: 900px;
          height: auto;
          margin-bottom: 17px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }

        // 时间分享
        .kOneRTim {
          width: 1145px;
          height: 15px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          span {
            width: auto;
            height: auto;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #b4b4b4;
          }

          div {
            width: auto;
            height: 15px;
            margin-right: 27px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;

            span {
              width: auto;
              height: auto;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #b4b4b4;
            }

            img {
              width: 15px;
              height: 15px;
              margin-left: 12px;
            }
          }
        }

        // 回复
        .kOneRHuifu {
          width: 1090px;
          height: auto;
          margin-top: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          border-bottom: 1px solid #cccccc;

          // 左头像
          .kOneRHuifuL {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 20px;
          }

          // 右内容
          .kOneRHuifuR {
            width: auto;
            height: auto;
            margin-top: 6px;

            // 回复内容
            .kOneRHuifuRp {
              width: 850px;
              height: auto;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              margin-bottom: 19px;

              span {
                color: #6786ff;
                margin-right: 12px;
              }
            }

            // 时间点赞
            .kOneRHuifuRtim {
              width: auto;
              height: 15px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 30px;

              span {
                width: auto;
                height: auto;
                margin-right: 12px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #b4b4b4;
              }

              img {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
      }
    }
  }
}

.nav-content {
  display: flex;
  width: 1220px;
  margin: 93px auto 0px;

  .class-list {
    width: 880px;
    /* background: #ffffff; */
    margin-right: 20px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
  }

  .youlove {
    width: 320px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    flex-shrink: 0px;
  }
}

.mask_layer {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  position: fixed;
  background-color: #000000;
  opacity: 0.6;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-vs {
  cursor: pointer;
}
</style>
